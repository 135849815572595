
import {
    STOP_CHECK_ELEMENT,
    STOP_CHECK_OPERATION_ELEMENT,
} from "./containers";

import { getSafeRandom } from '@platform/api'
/*
 * @Descripttion:
 * @version:
 * @Author: bbq
 * @Date: 2020-12-08 16:04:10
 * @LastEditors: bbq
 * @LastEditTime: 2020-12-11 14:38:00
 */
const getVarType = function (v) {
    return Object.prototype.toString.call(v).slice(8, -1);
};

/**
 * 判断是否为对象
 * @param {*object} obj 
 */
export function isObject(obj) {
    return getVarType(obj) === 'Object';
}

/* 检测类型是否为数组 */
export function isArray(param) {
    return getVarType(param) === 'Array' || Array.isArray(param);
}

/* 检测类型是否为字符串 */
export function isString(param) {
    return getVarType(param) === 'String';
}

/* 检测类型是否为方法 */
export function isFunction(param) {
    return getVarType(param) === 'Function';
}

/* 检测类型是否为Promise对象 */
export function isPromise(param) {
    return getVarType(param) === 'Promise';
}

/* 检测类型是否为数字 */
export function isNumber(param) {
    return getVarType(param) === 'Number' && !Number.isNaN(param);
}

/*
 * @method   检测是否是需要是boolean类型
 */
export function isBoolean(param) {
    return getVarType(param) === 'Boolean';
}

/*
 * @method   是否是undefined
 */
export function isUndefined(origin) {
    return typeof origin === 'undefined' || origin === void 0;
}

/*
 * @method   if条件下为false   除去NaN、0、-0、false   剩余undefined、null、""
 */
export function isWrong(param) {
    return typeof param === 'undefined' || param === null || param === '';
}

export function isNullOrVoid(param) {
    return typeof param === 'undefined' || param === null;
}

/*
 * @method   if条件下为false   除去NaN、0、-0、false   剩余undefined、null、""
 */
export function isWrongFalse(param) {
    return typeof param === 'undefined' || param === null || param === '' || param === false;
}

/**
 * 生成唯一标识
 */
export function uuidv4() {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (getSafeRandom() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export function getLists(source) {
    const lists = [];

    const handleLists = node => {
        lists.push(node);
        if (node.children) {
            node.children.map((n, i) => {
                handleLists(n);
            });
        } else {
            return;
        }
    };

    source.map(node => handleLists(node));

    return lists;
}

//   获取最深树层级 数
export function countDeep(source) {
    if (!source) return;
    // 铺平数据
    const lists = getLists(source);

    // 找到孙子  没有自己的儿子的都是孙子  所以我要加油找女朋友
    const sunLists = [];
    lists.map((v, i) => {
        if (!v.children || v.children.length == 0) sunLists.push(v);
    });

    // 现在查找孙子的层级
    const count = node => {
        let count = 0;
        const findFather = child => {
            count++;
            lists.map(node => {
                if (node.key === child.pid) {
                    findFather(node);
                } else {
                    return;
                }
            });
        };
        findFather(node);
        return count;
    };

    const saveCount = [];

    sunLists.forEach(node => {
        saveCount.push(count(node));
    });

    // 获取数组最大值 得到最深树层数
    if (saveCount.length === 0) {
        return 0;
    } else {
        console.log('最大深度',Math.max.apply(null, saveCount));
        return Math.max.apply(null, saveCount);
    }
}

function findParentTd(el) {
    while (el.parentElement) {
        el = el.parentElement;
        if (el.tagName === "TD") {
            return el;
        }
    }
    return null;
}

/**
 * @desc 如果点击的是操作列，展开图标，文本夹图标就不执行选中事件。 选中文本时也不执行
 * @param e {object} 事件源
 * @param checkOnRowClick {boolean} 配置是否需要勾选功能
 */
export function stopCheckBoxChange(e, checkOnRowClick) {
    //业务组配置不需要勾选功能
    if (checkOnRowClick === false) {
        return true;
    }

    //点击的是展开图标
    if (e.target.className.includes("u-table-row-expand-icon")) {
        return true;
    }

    //点击阻止选中按钮元素（文本夹图）
    if (e.target.className.includes(STOP_CHECK_ELEMENT)) {
        return true;
    }

    //点击操作列元素
    let td = findParentTd(e.target);

    if (td) {
        let operationContainer = td.querySelector(
            `.${STOP_CHECK_OPERATION_ELEMENT}`,
        );

        if (operationContainer && operationContainer.contains(e.target)) {
            td = operationContainer = null;
            return true;
        }
    }

    //选中文本后触发click事件
    if (document.getSelection().toString()) {
        return true;
    }
}

export function getChildren(data, parentKey) {
    if (data == null || data.length == 0) return false;
    let children = [];
    for (var i = 0; i < data.length; i++) {
        if (data[i].key == parentKey) {
            return data[i].children;
        } else if (Array.isArray(data[i].children) && data[i].children.length > 0) {
            children = children.concat(data[i].children);
        }
    }
    return getChildren(children, parentKey);
}
