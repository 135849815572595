/*
 *   create by wangshhj
 * */
import React from 'react';
import TreeTable from './treeTable';
import useTreeTable from './store';
import { getSafeRandom } from '@platform/api'
import { countDeep as utilCountDeep, stopCheckBoxChange as utilStopCheckBoxChange, getChildren as utilGetChildren } from './utils';

const MODALNAME = {
    add: "新增节点",
    edit: "编辑节点",
};

/**
 * @desc 保存列的宽度
 * @type {{
 *   'attrCode': Number
 * }}
 */
let cellWidth = {};
const PID = "root";

/**
 * 检测组件对象是否存在 bbq
 * @param {*} id 
 */
const existenceCheck = function (id) {
    let store = this.treeTableDataSource[id]
    if (!store) {
        store = useTreeTable();
        this.treeTableDataSource[id] = store;
        // 同步模板
        store.setMeta(this.meta.getMeta());
        console.warn(`所操作的表格中无ID为${id}的数据`);
        return;
    }
    return store;
}

// 创建组件
export function treeTableCol(moduleId, config = {}) {
    // this.register("treeTable"); // 生成 this.treeTableDataSource[id]
    let pageScope = this,
        store = pageScope.treeTableDataSource[moduleId];
    if (!store) {
        store = useTreeTable();
        pageScope.treeTableDataSource[moduleId] = store;
        // 同步模板
        store.setMeta(pageScope.meta.getMeta(), moduleId, false);
        console.log(pageScope);
    }

    let { async, ...others } = config;

    return <TreeTable
        async={async}
        store={store}
        id={moduleId}
        modal={this.modal}
        {...others}
    />
}

/*
 *    插入子节点数据
 *    @ child 子表数据
 *    @ parent 当前行信息
 * */
function setChildNodeEve(
    tableData,
    child,
    parent,
    checkedKeys = {},
    expandedKeys,
    isCheckedHasChild,
) {
    child = JSON.parse(JSON.stringify(child)); // 清理 并拷贝对象
    let parentKey = parent.key;
    let len = tableData.length;
    for (let i = 0; i < len; i++) {
        let item = tableData[i];
        if (item.rowId === parentKey) {

            if (expandedKeys && expandedKeys.length) {
                clearExpandedKeys(tableData[i].children, expandedKeys)
            }

            tableData[i].children = child;

            //父级勾选 包含下级时，勾选所有子级
            if (isCheckedHasChild && checkedKeys[item.rowId]) {
                setChecked(child, checkedKeys)
            }
            return true;
        } else if (Array.isArray(item.children)) {
            let res = setChildNodeEve(item.children, child, parent, checkedKeys, expandedKeys, isCheckedHasChild);
            if (res) {
                return false;
            }
        }
    }
}

/*
 *   添加rowId;
 *   field: rowId字段
 * */
function addRowId(data, field, expandedKeys) {
    if (!Array.isArray(data)) {
        console.warn("addRowId方法，第一个参数应为数组");
        return false;
    }
    if (data.length > 0 && !data[0].values[field]) {
        console.warn(`addRowId方法，没有找到参数${field}`);
        return false;
    }
    data.forEach(item => {
        item.key = item.rowId = item.values[field].value;
        expandedKeys && expandedKeys.push(item.values[field].value);
        if (Array.isArray(item.children)) {
            addRowId(item.children, field, expandedKeys);
        }
    });
}



/*
 *   根据isleaf 添加children
 * */
function addChildren(data) {
    data.forEach(item => {
        if (item.hasOwnProperty("isleaf") && !item.isleaf) {
            item.children = item.children || [];
        }
    });
}

/*
 *   编辑行事件
 * */
function editRow(tableData, newItem) {
    newItem = JSON.parse(JSON.stringify(newItem));
    let i = tableData.length;
    while (i--) {
        let item = tableData[i];
        if (item.rowId === newItem.rowId) {
            tableData[i] = { ...tableData[i], ...newItem };
            return true;
        } else {
            if (Array.isArray(item.children)) {
                let res = editRow(item.children, newItem);
                if (res) {
                    return false;
                }
            }
        }
    }
}

/*
 *   增加子节点事件
 * */
function addChildNode(tableData, newItem, parent, syncAddNode) {
    newItem = JSON.parse(JSON.stringify(newItem)); // 清理 并拷贝对象
    let pk = null;
    if (!parent) {
        if (!newItem.values.pid) {
            console.error("树状表添加子节点的方法没找到pid");
            return false;
        }
        pk = newItem.values.pid.value;
    } else {
        pk = parent.key;
    }
    let i = tableData.length;
    while (i--) {
        let item = tableData[i];
        if (item.rowId === pk) {
            if (!newItem.values.pid) {
                newItem.values.pid = { value: pk };
            }

            // addChildRowEve 补丁
            if (tableData[i] && syncAddNode) tableData[i].isleaf = false;

            if (Array.isArray(tableData[i].children)) {
                tableData[i].children.push(newItem);
            } else {
                if (syncAddNode) tableData[i].isleaf = false;
                tableData[i].children = [newItem];
            }
            return true;
        } else {
            if (Array.isArray(item.children)) {
                let res = addChildNode(
                    item.children,
                    newItem,
                    parent,
                    syncAddNode,
                );
                if (res) {
                    return false;
                }
            }
        }
    }
}

/*
 *   增加兄弟节点事件
 * */
function addBrother(tableData, newItem, brother) {
    newItem = JSON.parse(JSON.stringify(newItem)); // 清理 并拷贝对象
    let pk = brother.key;
    let len = tableData.length;
    for (let i = 0; i < len; i++) {
        let item = tableData[i];
        if (item.rowId === pk) {
            tableData.splice(i + 1, 0, newItem);
            return true;
        } else {
            if (Array.isArray(item.children)) {
                let res = addBrother(item.children, newItem, brother);
                if (res) {
                    return false;
                }
            }
        }
    }
}

/*
 *   删除行
 * */

function delRow(tableData, key) {
    let len = tableData.length;
    for (let i = 0; i < len; i++) {
        let item = tableData[i];
        if (item.rowId === key) {
            tableData.splice(i, 1);
            return true;
        } else {
            if (Array.isArray(item.children)) {
                let res = delRow(item.children, key);
                if (res) {
                    return false;
                }
            }
        }
    }
}

/*
 *   删除后检测节点是否还有子节点，若没有，删除children 。 只检测删除行的父元素
 * */
function checkHasChildren(tableData, parentRowId) {
    let i = tableData.length;
    while (i--) {
        let item = tableData[i];
        if (item.rowId === parentRowId) {
            if (Array.isArray(item.children) && item.children.length === 0) {
                delete item.children;
            }
            return true;
        } else {
            if (Array.isArray(item.children)) {
                let res = checkHasChildren(item.children);
                if (res) {
                    return false;
                }
            }
        }
    }
}

/**
 * 根据rowid获取当前行数据
 */
function getValueByRowIdEve(tableData, rowId) {
    let i = tableData.length;
    while (i--) {
        let item = tableData[i];
        if (item.rowId === rowId) {
            return item;
        } else {
            if (Array.isArray(item.children)) {
                let res = getValueByRowIdEve(item.children, rowId);
                if (res) {
                    return res;
                }
            }
        }
    }
}

/*  获取勾选项 */
function getSelectedRowEve(data, store) {
    let checkedKeys = store.getStore('checkedKeys');

    let res = [];
    const G = data => {
        data.map(item => {
            if (checkedKeys[item.key]) {
                res.push(item);
            }
            if (Array.isArray(item.children)) {
                G(item.children);
            }
        });
    };
    G(data);
    return res;
}

/**
 * 设置勾选项
 * @param {*} moduleId
 * @param {*} checkedKeys
 * @param {*} merge
 */
export function setCheckedKeys(moduleId, checkedKeys, merge) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }

    let oldCheckedKeys = store.getStore('checkedKeys');

    if (merge) {
        checkedKeys = Object.assign(oldCheckedKeys, checkedKeys);
    } else {
        checkedKeys = Object.assign({}, checkedKeys);
    }

    store.setStore('checkedKeys', checkedKeys);
}

/**
 * 是否包含下级---未导出
 * @param {string} moduleId
 */
export function isCheckHasChild(moduleId) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    return store.getStore('isCheckedHasChild');
}

/**
 * 设置选中行
 * @param {*} moduleId
 * @param {*} record
 */
export function setSelectedRow(moduleId, record) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    store.setStore('currentSelectedRow', record);
}

/*==================================================================================*/

/*
 *   初始化树状表数据
 *   @ data 数据
 *   @ rowId  表示行id的字段名
 *   @ 默认展开所有行 defaultExpandAllRows
 * */
export function initTreeTableData(
    moduleId,
    data,
    rowId,
    defaultExpandAllRows,
    expandedRowKeys,
) {
    if (rowId === undefined) {
        console.warn("树状表addRowId方法，第三个参数需要传表示行rowid 的字段");
        return false;
    }
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    let { async, isCheckedHasChild, checkedKeys } = store.getStore();
    if (async) {
        //异步时，需要添加children字段。
        addChildren(data);
    }
    let res,
        expandedKeys = [];
    if (defaultExpandAllRows) {
        res = addRowId(
            data,
            rowId,
            expandedKeys,
        );
        if (isCheckedHasChild && checkedKeys) {
            data.map(item => {
                if (checkedKeys[item.key] && Array.isArray(item.children)) {
                    setChecked(item.children, checkedKeys)
                }
            });
        }
    } else {
        res = addRowId(data, rowId);
        expandedRowKeys && (store.setStore('expandedKeys', expandedRowKeys, false));
    }
    if (res === false) {
        console.warn(
            `树状表addRowId方法，没有找到表示rowId的字段，请检查数据中是否存在${rowId}字段`,
        );
        return false;
    }
    store.setData({ data, shouldForceUpdate: false });
    store.setStore('rowId', rowId, false);
    isCheckedHasChild && (store.setStore('checkedKeys', checkedKeys, false));
    defaultExpandAllRows && (store.setStore('expandedKeys', expandedKeys, false));
    // 缓存数据以用于  前端查找 --bbqin
    store.setCache('cacheData', JSON.parse(JSON.stringify(data)));
    store.setStore('searchValue', null, true);
}

//设置子数据勾选
function setChecked(children, checkedKeys) {
    if (Array.isArray(children)) {
        children.forEach(item => {
            checkedKeys[item.key] = true;
            if (Array.isArray(item.children)) {
                setChecked(item.children);
            }
        });
    }
}
//设置子数据勾选
function clearExpandedKeys(children, expandedKeys) {
    if (Array.isArray(children)) {
        children.forEach(item => {
            let index = expandedKeys.indexOf(item.key)
            if (index !== -1) {
                expandedKeys.splice(index, 1)
            }
            if (Array.isArray(item.children)) {
                clearExpandedKeys(item.children, expandedKeys);
            }
        });
    }
}

export function updataDataSource(moduleId, callBack) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    let data = store.getData();

    if (callBack) {
        const newDataSource = callBack(
            JSON.parse(JSON.stringify(data)),
        );
        if (Array.isArray(newDataSource)) {
            store.setCache('cacheData', newDataSource);
            // 更新
            store.setData({ data: newDataSource });
        }
    } else {
        console.warn("请传回调参数，并且返回新的数据源");
    }
}

/**
 * 查询树表
 * @param {String} moduleId 模块Id
 * @param {String} searchValue 查詢字符串
 * @param {Array} filters 过滤关键字
 */
export function searchTreeTable({
    moduleId,
    searchValue,
    filters = [],
    expanded = true,
    defaultExpandAllRows = false,
}) {

    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }

    if (Object.prototype.toString.call(filters) !== "[object Array]") {
        // 如果不是数组
        filters = [String(filters)];
    }
    // 确定是不是检索所有字段
    let data = store.getCache('cacheData') || store.getData(),
        expandedKeys = [],
        expectData = [];
    // 清空 数据
    store.setStore('expandedKeys', [], false);

    let searchResult = findDataByValueFilters({
        data,
        searchValue,
        filters,
        expandedKeys,
        expanded,
        defaultExpandAllRows,
        expectData,
    });

    // 展开的键 expandedKeys 检索后是否展开 理论上被检索到的行应该展开
    store.setData({
        data: searchResult,
        shouldForceUpdate: false
    });
    console.log(data);
    // 记录一下关键字 用于做替换
    store.setStore('searchValue', searchValue, false);
    store.setStore('expandedKeys', expandedKeys, false);
    store.refresh(() => {
        // store.setStore('searchValue', null, false);
    });
}

/**
 * 遍历  并且查询  符合要求的数据
 * @param {*} param0
 */
function findDataByValueFilters({
    data,
    expandedKeys,
    searchValue,
    filters,
    expanded,
    defaultExpandAllRows,
    expectData = [],
}) {
    if (!searchValue || data.length === 0) {
        return data || [];
    }
    let needFilters = false,
        filtersLength = filters.length;
    // 不区分大小写
    searchValue = searchValue.toLocaleLowerCase();
    data.map(item => {
        let isMatch = false,
            values = item.values,
            value;
        let expectItem = JSON.parse(
            JSON.stringify(item, function (k, v) {
                if (
                    k === "children" &&
                    Object.prototype.toString.call(v) === "[object Array]"
                ) {
                    return null;
                }
                return v;
            }),
        ); // 数据拷贝
        // console.log(expectItem);
        for (let vi in values) {
            // 该字段是否需要过滤
            needFilters =
                filtersLength === 0 ? true : filters.indexOf(vi) !== -1;
            // 不区分大小写
            value = String(
                values[vi]["display"] || values[vi]["value"],
            ).toLocaleLowerCase();
            if (needFilters && value && value.indexOf(searchValue) !== -1) {
                // 匹配到行
                isMatch = true;
                break;
            }
        }
        if (Array.isArray(item.children)) {
            // 子循环
            expectItem.children = findDataByValueFilters({
                data: item.children,
                expandedKeys,
                searchValue,
                filters,
                expanded,
                defaultExpandAllRows,
                expectData: [],
            });
            expectItem.children.length === 0 && (expectItem.children = null);
        }
        (isMatch || (expectItem.children && expectItem.children.length > 0)) &&
            expectData.push(expectItem);
        if (
            (isMatch ||
                (expectItem.children && expectItem.children.length > 0)) &&
            (expanded || defaultExpandAllRows)
        ) {
            // 默认展开 或者全部展开
            expandedKeys.push(item.key || item.rowId);
        }
    });
    return expectData;
}

/*
 *   异步加载时，设置子表数据
 *    @ child 子表数据
 *    @ parent 点击的当前行数据
 * */
export function setChildNode(moduleId, child, parent, isCheckedHasChild) {
    if (!moduleId || !child || !parent) {
        console.warn("树状表setChildNode 方法参数不正确");
        return false;
    }
    if (!Array.isArray(child)) {
        console.warn("树状表setChildNode 方法,第二个参数应为数组");
        return false;
    }
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }

    let rowId = store.getStore('rowId');
    let async = store.getStore('async');
    let checkedKeys = store.getStore('checkedKeys');
    let expandedKeys = store.getStore('expandedKeys');
    let data = store.getData();
    let cacheData = store.getCache('cacheData');

    isCheckedHasChild = isCheckedHasChild || store.getStore('isCheckedHasChild');

    if (async) {
        addChildren(child);
        addRowId(child, rowId);
        setChildNodeEve(
            data,
            child,
            parent,
            checkedKeys,
            expandedKeys,
            isCheckedHasChild,
        );
        // 更新缓存数据以用于查找 by bbqin
        setChildNodeEve(
            cacheData,
            child,
            parent,
            checkedKeys,
            expandedKeys,
            isCheckedHasChild,
        );
        // 更新
        store.setStore('searchValue', null);
    } else {
        console.warn("setChildNode方法只能异步加载时调用");
    }
}

export function getChildren(data, parentKey) {
    return utilGetChildren(data, parentKey);
}

/*
 *   编辑行
 * */
export function editRowEve(moduleId, newData) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    let rowId = store.getStore('rowId');
    let data = store.getData();
    let cacheData = store.getCache('cacheData');

    if (!newData.values[rowId]) {
        console.warn &&
            console.warn(
                `树状表editRowEve 方法,第二个参数中没有找到表示RowId的字段，请检查数据中是否有${rowId}字段`,
            );
        return false;
    }
    newData.rowId = newData.values[rowId].value;
    addChildren([newData]);
    editRow(data, newData);
    // 更新缓存数据以用于查找 by bbqin
    editRow(cacheData, newData);

    store.setData({ data: data, shouldForceUpdate: false });
    
    // 更新
    store.setStore('searchValue', null);
}

/*
 *   增加子节点
 * */
export function addChildRowEve(moduleId, newData) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    let rowId = store.getStore('rowId');
    let currentRow = store.getStore('currentRow');
    let data = store.getData();
    let cacheData = store.getCache('cacheData');

    console.log(newData, currentRow, data, cacheData);

    let runThis = item => {
        let kValue = item.values[rowId];
        item.values[rowId] =
            kValue && kValue.value !== undefined && kValue.value !== null
                ? kValue
                : { value: getSafeRandom() };
        // if (!item.values[rowId]) {
        // 	console.warn(`树状表editRowEve 方法,第二个参数中没有找到表示RowId的字段，请检查数据中是否有${rowId}字段`);
        // 	return false;
        // }
        item.key = item.rowId = item.values[rowId].value;
        addChildren([item]);

        addChildNode(data, item, currentRow, true);
        // 更新缓存数据以用于查找 by bbqin
        addChildNode(cacheData, item, currentRow, true);

        store.setStore('searchValue', null, false);
    };
    if (Array.isArray(newData)) {
        newData.forEach(items => {
            runThis(items);
        });
    } else {
        runThis(newData);
    }

    // 更新
    store.refresh();
}
/*
 *   更新全部子节点
 * */
export function updateChildRow(moduleId, newData, parentRow) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }

    let rowId = store.getStore('rowId');
    let currentRow = store.getStore('currentRow');
    let data = store.getData();

    let newDataArr = []
    let pk = "root"
    if (currentRow) pk = currentRow.key
    if (parentRow) pk = parentRow.key
    if (Array.isArray(newData)) {
        newDataArr = newData
    } else {
        newDataArr = [newDataArr]
    }
    const loop = (data, pk) => {
        if (!data.length) return
        data.map(item => {
            let kValue = item.values[rowId];
            item.values[rowId] = kValue && kValue.value !== undefined ? kValue : { value: getSafeRandom() };
            item.key = item.rowId = item.values[rowId].value;
            if (!item.values.pid) {
                item.values.pid = { value: pk };
            }
            if (item.hasOwnProperty("isleaf") && !item.isleaf) {
                item.children = item.children || [];
                loop(item.children, item.key)
            }
        })
    }
    const updateRowByRowId = (tableData, rowId) => {
        let i = tableData.length;
        while (i--) {
            let item = tableData[i];
            if (item.rowId === rowId) {
                tableData[i].children = newDataArr
                tableData[i].isleaf = false
                return true
            } else {
                if (Array.isArray(item.children)) {
                    let res = updateRowByRowId(item.children, rowId)
                    if (res) return true
                }
            }
        }
    }
    loop(newDataArr, pk) // 处理节点数据，检查key，rowId，children
    if (!updateRowByRowId(data, pk)) {
        data = newDataArr
    }
    // 更新
    store.setStore('searchValue', null);
}

/*
 *   增加兄弟节点
 * */
export function addBrotherRow(moduleId, newData) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    let rowId = store.getStore('rowId');
    let currentRow = store.getStore('currentRow');
    let data = store.getData();
    let cacheData = store.getCache('cacheData');

    if (!newData.values[rowId]) {
        console.warn(
            `树状表addBrotherRow 方法,第二个参数中没有找到表示RowId的字段，请检查数据中是否有${rowId}字段`,
        );
        return false;
    }
    newData.rowId = newData.values[rowId].value;
    addChildren([newData]);
    addBrother(data, newData, currentRow);
    // 更新缓存数据以用于查找 by bbqin
    addBrother(cacheData, newData, currentRow);
    // 更新
    store.setStore('searchValue', null);
}

/*
 *   删除节点
 * */
export function delRowByPk(moduleId, record) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }

    let data = store.getData();
    let cacheData = store.getCache('cacheData');

    let key = record.key;
    delRow(data, key);
    // 更新缓存数据以用于查找 by bbqin
    delRow(cacheData, key);
    if (record.pid) {
        checkHasChildren(data, record.pid);
        // 更新缓存数据以用于查找 by bbqin
        checkHasChildren(cacheData, record.pid);
    }

    // 更新数据
    store.setData({ data: data, shouldForceUpdate: false });
    store.setCache('cacheData', cacheData);
    // 更新
    store.setStore('searchValue', null);
}

/*
 *   同步时，组装树状表所需要的数据结构
 * */
export function createNewData(data) {
    if (!Array.isArray(data)) {
        console.warn("createTreeData方法，参数应该为数组");
        return false;
    }
    let group = new Map();
    data.forEach(e => {
        if (!(e.values.pid && e.values.pid.value)) {
            e.values.pid = { value: PID };
        }
        group.get(e.values.pid.value)
            ? group.get(e.values.pid.value).push(e)
            : group.set(e.values.pid.value, [e]);
    });

    function makeDOM(pid) {
        if (group.get(pid)) {
            return group.get(pid).map(e => {
                e.children = makeDOM(e.values.refpk.value);
                return e;
            });
        } else {
            return null;
        }
    }
    let newData = makeDOM(PID) || [];
    /* 添加isleaf字段 */
    const addIsLeaf = data => {
        data.forEach(item => {
            if (item.checked) {
                item.checked = true;
            } else {
                item.checked = false;
            }
            if (Array.isArray(item.children)) {
                item.isleaf = false;
                addIsLeaf(item.children);
            } else {
                item.isleaf = true;
            }
        });
    };

    addIsLeaf(newData);

    return newData;
}

/*
 *   获取当前表格所有数据
 * */
export function getAllValue(moduleId) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    return store.getData();
}

export function getExpandedRowKeys(moduleId) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    return store.getStore('expandedKeys');
}

/*
 *   获取当前表格某行数据 byId
 * */
export function getValueByRowId(moduleId, rowId) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    let data = store.getData();
    return getValueByRowIdEve(data, rowId);
}

/*
 *   弹出框  (要改)
 * */
export function showModal(moduleId, record, modalType) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    let langJson = store.getCache('langJson');
    let formStore = store.getStore('formStore');
    let uuid = store.getStore('uuid');

    MODALNAME.add = langJson["insert-tree-table-012"];
    MODALNAME.edit = langJson["insert-tree-table-011"];

    store.setStore('currentRow', record, false);
    // this.state.form[moduleId + 'form'] = modalType === 'edit' ? record.values : {};
    const formValues = JSON.parse(JSON.stringify(modalType === "edit" ? record.values : {}));

    console.log(formValues, formStore);
    formStore.emptyAllFormValue();
    formStore.setAllFormValue(formValues);

    store.setStore('modalType', modalType, true);

    this.modal.show("treeTableManyColModal", { title: `${MODALNAME[modalType]}` });
}

/* 清空表格 */
export function emptyAllData(moduleId) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }

    store.setData({ data: [], shouldForceUpdate: false });
    store.setCache('cacheData', []);
    // 更新缓存数据以用于查找 by bbqin
    store.setStore('searchValue', null);
}

/* 展开节点*/
export function openRow(moduleId, key) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }

    let expandedKeys = store.getStore('expandedKeys');

    !expandedKeys.find(i => i === key) &&
        expandedKeys.push(key);
    store.refresh();
}

/* 收起节点*/
export function closeRow(moduleId, key) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }

    let expandedKeys = store.getStore('expandedKeys');
    let index = expandedKeys.findIndex(i => i === key);
    expandedKeys.splice(index, 1);
    store.refresh();
}

/* 获取选中行数据 */
export function getSelectedRow(moduleId) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    let data = store.getData();
    let expandedKeys = store.getStore('expandedKeys');
    let isCheckedHasChild = store.getStore('isCheckedHasChild');

    const selectedRows = getSelectedRowEve(data, store);
    let res = JSON.parse(JSON.stringify(selectedRows));

    res = res.map(item => {
        let { children, ...others } = item;
        //勾选节点未展开, 且children不存在
        if (
            !expandedKeys.includes(item.key) &&
            (!children || (Array.isArray(children) && children.length === 0))
        ) {
            //若勾选项不是叶子节点，且设置了isCheckHasChild:true
            if (item.isleaf !== false && isCheckedHasChild) {
                others.isCheckHasChild = true;
            }
        }
        return others;
    });
    return res;
}

// /* 获取选中行数据 */
// export function getSelectedRow(moduleId) {

// }

//   获取最深树层级 数
export function countDeep(source) {
    return utilCountDeep(source);
}

/**
 * @desc 如果点击的是操作列，展开图标，文本夹图标就不执行选中事件。 选中文本时也不执行
 * @param e {object} 事件源
 * @param checkOnRowClick {boolean} 配置是否需要勾选功能
 */
export function stopCheckBoxChange(e, checkOnRowClick) {
    return utilStopCheckBoxChange(e, checkOnRowClick);
}


/**
 * @desc 判断此单元格是否需要显示tooltip
 * @param cellDom {object} 单元格dom
 * @param attrCode {string}
 * @param fixed {string} 锁定列表
 * @return {boolean} true -> 显示tooltip, false -> 不显示
 */
export function perceptionTooltip({ cellDom, attrCode, fixed }) {
    const PRE_FIX = "nc_tree";
    let id = `${PRE_FIX}_${attrCode}`;
    let cloneDom = document.getElementById(id);

    if (cloneDom) {
        cloneDom.innerText = cellDom.innerText;
        return cloneDom.offsetWidth > cellWidth[attrCode];
    } else if (fixed === "left") {
        let fixedDom = document.querySelectorAll(
            `.u-table-fixed-left span[fieldid="${attrCode}"] span`,
        )[0];

        if (fixedDom === null) return false;

        cloneDom = createCloneDomAndSetAttribute({
            id,
            attrCode,
            cellWidth,
            originDom: fixedDom,
        });
        return cloneDom.offsetWidth > fixedDom.offsetWidth;
    } else {
        cloneDom = createCloneDomAndSetAttribute({
            id,
            attrCode,
            cellWidth,
            originDom: cellDom,
        });

        return cloneDom.offsetWidth > cellDom.offsetWidthh;
    }
}

function createCloneDomAndSetAttribute({ originDom, id, attrCode, cellWidth }) {
    let cloneDom = originDom.cloneNode();
    cloneDom.className = "u-cell-hidden";

    cloneDom.id = id;
    document.body.appendChild(cloneDom);
    cloneDom.innerText = originDom.innerText;
    cloneDom.height = "0px";
    cellWidth[attrCode] = originDom.offsetWidth;
    return cloneDom;
}
