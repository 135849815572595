/*
 * @Descripttion:
 * @version:
 * @Author: bbq
 * @Date: 2020-12-08 14:51:21
 * @LastEditors: bbq
 * @LastEditTime: 2020-12-24 15:08:59
 */
import { BaseStore, hookFactory } from "@platform/template";
import { isArray, isUndefined, isNullOrVoid, isObject, uuidv4, isFunction } from './utils';
import Form from '@platform/form';

class Store extends BaseStore {

    constructor(props) {
        super(props);
        this.name = "treeTableStore";
        this.refresh = this.refresh.bind(this);
        this.uuid = this.store.uuid = uuidv4();
    }

    // 数据源
    store = {
        // 表单组件对象
        formStore: Form.useForm(),
        // 表格数据
        data: [],

        // 对象模式数据
        fullDataObject: {},

        // 列模板
        meta: {},

        // 表格属性
        async: false,

        rowId: 'rowId',

        // 是否子
        isCheckedHasChild: false,

        // 展开项
        expandedKeys: [],

        // 选中项
        checkedKeys: {},
        // 半选
        indeterminateKeys: {},

        currentSelectedRow: null,
        currentRow: null,

        // 弹窗类型
        modalType: null,

        // 折行
        isEllipsis: true,

        // 搜索结果
        searchValue: '',
    };

    // 缓存
    cache = {
        cacheData: [],
        langJson: {},
    };

    /**
     * @description: 复写
     * @param {type}
     * @return:
     */
    getStore(paths) {
        return super.get(paths);
    }

    /**
     * @description: 复写
     * @param {type}
     * @return:
     */
    setStore(paths, value, shouldForceUpdate = true, callback) {
        let res = super.set(paths, value);
        shouldForceUpdate && this.forceUpdate(callback);
        return res;
    }

    /*-------------缓存-------------*/

    /**
     * @description: 设置缓存
     * @param {type}
     * @return:
     */
    setCache(path, value) {
        if (!path) {
            console.warn("invalid params <path> to setting");
            return;
        }
        if (!isArray(path)) {
            path = [path];
        }
        let current = this.cache;
        let len = path.length;
        path.forEach((item, index) => {
            if (!current[item]) {
                current[item] = {};
            }
            if (index === len - 1) {
                current[item] = value;
            } else {
                current = current[item];
            }
        });
    }

    /**
     * @description: 获取缓存
     * @param {type}
     * @return:
     */
    getCache(paths) {
        if (isUndefined(paths)) {
            console.warn("invalid params <paths> to setting");
            return;
        }
        if (!isArray(paths)) {
            paths = [paths];
        }
        let current = this.cache;
        paths.some(item => {
            if (isUndefined(item) || isNullOrVoid(current)) {
                isUndefined(item) &&
                    console.warn("paths has empty item[%s]", paths);
                return;
            }
            if (!current[item]) {
                current = null;
                return;
            }
            current = current[item];
            return true;
        });
        // console.log(this.cache);
        return current;
    }

    /*-------------私有-------------*/

    /*-------------列-------------*/
    /**
     * @description: 设置所有meta
     * @param {type}
     * @return:
     */
    setMeta(meta, id, shouldForceUpdate = true) {
        let name = this.getStore("name");
        id && this.setStore("name", id || name, false);

        // 我觉得这里应该 考虑page上的meta更新
        this.setStore("meta", meta, shouldForceUpdate);

        // 更新表单的meta
        let formStore = this.getStore('formStore');
        if (formStore && meta[id] && isArray(meta[id].items)) {
            let formColumns = [];
            meta[id].items.map(item => {
                if (item.itemtype !== 'customer') {
                    // item 内部东西太复杂 直接给会导致一些问题
                    let nItem = JSON.parse(
                        JSON.stringify(item || {}, (key, value) => {
                            if (key == 'render' || key == 'title') {
                                // title 和 value 是react对象  会导致问题
                                return undefined;
                            }
                            return value;
                        }),
                    );
                    delete nItem.render;
                    delete nItem.title;
                    formColumns.push(nItem);
                }
            });

            console.log(formColumns);

            formStore.setMeta({
                [this.uuid]: {
                    status: 'edit',
                    items: formColumns,
                    moduletype: "form",
                    isunfold: false,
                },
            }, this.uuid);
        }
    }

    /**
     * @description: 获取所有meta
     * @param {type}
     * @return:
     */
    getMeta = () => {
        let meta = this.getStore("meta");
        return meta;
    };

    /**
     * @description: 是否包含当前meta
     * @param {type}
     * @return:
     */
    _hasMeta(id) {
        let meta = this.getStore("meta") || {};
        if (!id || !isObject(meta[id]) || !meta[id].items) {
            console.warn('not find meta');
            return [];
        }
        return meta[id].items;
    }

    /**
     * @description: 获取id所在视图的meta
     * @param {type}
     * @return:
     */
    getViewMeta(id) {
        let viewMeta = this._hasMeta(id);
        return viewMeta;
    }

    /**
     * @description: 设置数据
     * @param {type}
     * @return:
     */
    setData({ data = [], shouldForceUpdate = true, callback }) {
        let res = isArray(data) ? data : this.getData();
        // console.log(res);
        // 格式化data
        this.setStore(["data"], res, shouldForceUpdate, callback);
    }

    /**
     * regexp 可以是方法 也可以是参数
     * @description: 获取数据
     * @param {type}
     * @return:
     */
    // @infoLog
    getData(regexp = []) {
        let data = this.getStore("data");
        // console.log(data);
        // 添加规则什么的  比如删除 

        data = data.filter(item => {
            return !regexp.includes(item.status);
        });
        return data || [];
    }

    // 刷新整个绑定对象
    refresh(callback) {
        this.forceUpdate && this.forceUpdate(callback);
    }

    getHooks = () => {
        // 这样处理的一下 才能报错  方法内部的this 永远指向 实例  否则会指向下面的 {} 对象
        this.getMeta = this.getMeta.bind(this);
        this.setMeta = this.setMeta.bind(this);
        return {
            // 按路径索引 给内部使用
            getStore: this.getStore.bind(this),
            setStore: this.setStore.bind(this),
            /*-------------------上面的部分------------------*/
            //模板
            getMeta: this.getMeta.bind(this),
            setMeta: this.setMeta.bind(this),
            getViewMeta: this.getViewMeta.bind(this),

            // 缓存
            getCache: this.getCache.bind(this),
            setCache: this.setCache.bind(this),

            //
            setData: this.setData.bind(this),
            getData: this.getData.bind(this),

            // 刷新
            refresh: this.refresh,
        };
    }
}

export default hookFactory(Store);