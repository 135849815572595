import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { Tooltip as NCTooltip } from '@platform/base';
import { getSysFieldid } from '@platform/api';

class Cell extends Component {
    constructor(props) {
        super(props);
        this.state = { tipStatus: false };
    }

    componentWillUnmount() {
        this.cellDom = null;
    }

    handleMouseOver = () => {
        if (this.cellDom) {
            let { tipStatus } = this.state;
            let firstCol = this.cellDom.parentNode.getAttribute('class').includes('first-col');
            let tipNode = null;
            let cellParent = this.cellDom.parentNode;
            let realNode = this.cellDom.querySelector('.cell-context')
            if (cellParent) {
                tipNode = firstCol ? cellParent.parentNode && cellParent.parentNode.parentNode : cellParent;
            }
            if (tipNode) {
                if (realNode.offsetWidth > tipNode.clientWidth && tipStatus === false) {
                    this.setState({ tipStatus: true });
                }
                if (realNode.offsetWidth <= tipNode.clientWidth && tipStatus === true) {
                    this.setState({ tipStatus: false });
                }
            }

        }
    };

    render() {
        let { item, fixedStyle, context, multipleRowCell, searchValue, beforeStr, index, afterStr, record, onCellMouseDown, isEllipsis, bodyExpandAllInRow } = this.props;
        return (
            <div
                fieldid={getSysFieldid(item.attrcode)}
                className={classnames('dobule-text', { 'dobule-text-fixed': !!(item.fixed && item.fixed === 'left') })}
                style={fixedStyle}
                ref={dom => {
                    this.cellDom = ReactDOM.findDOMNode(dom);
                }}
                onMouseOver={this.handleMouseOver}
                onMouseDown={e => {
                    onCellMouseDown && onCellMouseDown(record, index, item.attrcode, item, e);
                }}
            >
                <NCTooltip
                    placement="top"
                    overlay={this.state.tipStatus ? context : ''}
                    className="tooltip-word-color"
                    delay={1}
                    inverse
                >
                    <div className={classnames('double-text-cell', {
                        'single-line-and-ellipsis': !multipleRowCell && isEllipsis,
                        'three-line-and-ellipsis': !isEllipsis,
                        'mult-line-and-ellipsis': !isEllipsis && bodyExpandAllInRow,
                    })}>
                        {(beforeStr || afterStr || searchValue) ?
                            <span className="cell-context">
                                {beforeStr}
                                {index !== -1 && !!searchValue ?
                                    < span className="u-table-searchable-filter">{searchValue}</span> : ''}
                                {afterStr}
                            </span>
                            : <span className="cell-context">&nbsp;</span>}  {/* 单元格无数据时，显示空格，为了自动化测试可以点击到该单元格*/}
                    </div>
                </NCTooltip>
            </div>
        );
    }
}

export default Cell;
