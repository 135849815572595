/*
 * @Descripttion:树状表组件
 * @version:
 * @Author: bbq
 * @Date: 2020-12-07 17:01:56
 * @LastEditors: bbq
 * @LastEditTime: 2021-02-26 14:01:42
 */
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { Table, Checkbox, Selectable as NCSelectable } from '@platform/base';
import { isUndefined, isFunction, isArray, getLangCode, getMultiLang, getSysFieldid, pageTo, changeTime, formatDatetime, ViewModel } from '@platform/api';
import Cell from './Cell';
import { STOP_CHECK_ELEMENT, STOP_CHECK_OPERATION_ELEMENT } from './containers';
import { BaseComponent } from '@platform/template';
import Form from '@platform/form';
import { getChildren, stopCheckBoxChange, countDeep, getLists } from './utils';
import useTreeTable from './store';
require('./treeTableManyCol.less');

const defaultColor = ['#111111', '#555555', '#111', '#555'];

const { getGlobalStorage } = ViewModel;

class TreeTable extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            langJson: {},
            _: '',
        };
        const { store, id, isCheckedHasChild, async } = props;
        store.setStore('name', id, false);
        // 表单
        this.uuid = store.getStore('uuid');

        // 计算主表表体高度
        let tableHeight = 0;
        const screenHeight = window.screen.width;
        if (screenHeight <= 1919 > 1440) {
            tableHeight = 48 * 10 + 'px';
        } else if (screenHeight > 1919) {
            tableHeight = 56 * 10 + 'px';
        } else {
            tableHeight = 40 * 10 + 'px';
        }
        store.setStore('tableHeight', tableHeight, false);

        store.setStore('isCheckedHasChild', isCheckedHasChild, false);
        store.setStore('async', async, false);
        this.TreeTableRef = React.createRef();
    }

    componentDidMount() {

    }

    UNSAFE_componentWillMount() {
        const { store } = this.props;
        const { setCache } = store;
        //加载多语
        const callback = json => {
            // this.setState({ json });
            setCache('langJson', json || {});
            this.setState({ langJson: json });
        };
        getMultiLang({ moduleId: "containers_insert_tree_table", callback });
    }

    componentWillReceiveProps(nextProps) {
        const { store, isCheckedHasChild, async } = nextProps;
        isUndefined(isCheckedHasChild) && store.setStore('isCheckedHasChild', isCheckedHasChild, false);
        store.setStore('async', async, false);
    }

    componentWillUnMount() {
    }

    /* setState */
    setStateEve = () => {
        this.forceUpdate();
    };

    // 查找数据类型，并返回
    checkDataType = field => {
        const { store, id } = this.props;
        const meta = store.getMeta();
        const columns = meta[id];
        let data = columns.items.find(item => item.attrcode === field);
        if (data) {
            return data.itemtype;
        }
    };

    //  处理数据格式
    createNewData = (data, fullDataObject = {}, childKeyRecord = []) => {
        const dataSource = data.map((item, index) => {
            item.key = item.rowId;
            // 记录一下行顺序给 框选使用
            item.rowIndex = index;
            fullDataObject[item.key] = fullDataObject[item.key] || {};

            if (item.values.pid) {
                item.pid = item.values.pid.value;
            }
            if (Array.isArray(item.children)) {
                let childrenKeys = [];
                this.createNewData(item.children, fullDataObject, childrenKeys);
                fullDataObject[item.key].childrenKeys = childrenKeys;
            }
            fullDataObject[item.key].item = item;
            // 取值给父级
            childKeyRecord.push(item.key);
            return item;
        });
        return dataSource;
    };

    /* 复选框change事件(多选) */
    itemCheckBoxChange = (data, key) => {
        let { store, showContextMenu } = this.props;
        let checkedKeys = store.getStore('checkedKeys');
        let indeterminateKeys = store.getStore('indeterminateKeys');
        checkedKeys[key] = !checkedKeys[key];
        let isCheckedHasChild = store.getStore('isCheckedHasChild');

        function setChecked(children) {
            if (Array.isArray(children)) {
                children.forEach(item => {
                    checkedKeys[item.key] = checkedKeys[key];
                    if (Array.isArray(item.children)) {
                        setChecked(item.children);
                    }
                })
            }
        }

        //包含下级时，全部选中
        if (isCheckedHasChild && showContextMenu) {
            let children = getChildren(data, key);
            console.log("设置children选中:  children", children)
            setChecked(children);
        }

        //只有在勾选下级选中情况下才会设置半选状态
        if (isCheckedHasChild) {
            //设置半选状态
            indeterminateKeys[key] = false;
            const fullDataObject = store.getStore('fullDataObject') || {};
            let pid = fullDataObject[key] && fullDataObject[key].item.pid;
            function setPidCheckedStatus(pid) {
                function getAllChilrenList() {
                    let children = getChildren([fullDataObject[pid].item], pid);
                    let treeToList = getLists(children);
                    return treeToList;
                }

                let allChilrenList = getAllChilrenList();
                let allLength = allChilrenList.length;
                let checkedLength = allChilrenList.filter(x => checkedKeys[x.key]).length;
                if (allLength === checkedLength) {
                    checkedKeys[pid] = true;
                    indeterminateKeys[pid] = false;
                } else if (checkedLength) {
                    checkedKeys[pid] = false;
                    indeterminateKeys[pid] = true;
                } else {
                    checkedKeys[pid] = false;
                    indeterminateKeys[pid] = false;
                }
            }
            while (pid) {
                //indeterminateKeys[pid] = true;
                if (fullDataObject[pid] && fullDataObject[pid].item) {
                    setPidCheckedStatus(pid);
                }
                pid = fullDataObject[pid] && fullDataObject[pid].item && fullDataObject[pid].item.pid;
            }
            /*  let OriginFullDataObj = {...fullDataObject};
            delete OriginFullDataObj["all"];
            let OriginCheckedKeys = {...checkedKeys};
            delete OriginCheckedKeys["all"];
            let allKeysCount = Object.keys(OriginFullDataObj).length;
            let checkedCount = Object.values(OriginCheckedKeys).filter(x=>x).length;
            if(allKeysCount === checkedCount){
                checkedKeys["all"] = true;
                indeterminateKeys["all"] = false;
            }else if(checkedCount){
                checkedKeys["all"] = false;
                indeterminateKeys["all"] = true;
            }else{
                checkedKeys["all"] = false;
                indeterminateKeys["all"] = false;
            } */
        }

        store.setStore('checkedKeys', checkedKeys, false);
        store.setStore('indeterminateKeys', indeterminateKeys, true);
    };

    /* 全选按钮change事件 */
    selectAllChange = checked => {
        /*  let { store } = this.props;
        const fullDataObject = store.getStore('fullDataObject') || {};
        let checkedKeys = store.getStore('checkedKeys');
        let indeterminateKeys = store.getStore('indeterminateKeys');
        let allKeys = Object.keys(fullDataObject);
        checkedKeys["all"] = checked;
        indeterminateKeys["all"] = false;
        for (let key of allKeys) {
            checkedKeys[key] = checked;
            indeterminateKeys[key] = false;
        }
        store.setStore('checkedKeys', checkedKeys, false);
        store.setStore('indeterminateKeys', indeterminateKeys, true); */
    }

    /* 复选框change事件(单选) */
    checkBoxChange_radio = (data, key) => {
        let { store } = this.props;
        let checkedKeys = store.getStore('checkedKeys');

        if (checkedKeys[key]) {
            checkedKeys = {};
        } else {
            checkedKeys = { [key]: true };
        }
        store.setStore('checkedKeys', checkedKeys, false);
    };

    /* 复选框选中事件 */
    onCheckboxChangeChild = (text, record, index, e) => {
        let { store, checkboxChange, checkedType } = this.props;
        let data = store.getData();

        e.stopPropagation();
        if (checkedType === 'radio') {
            //单选
            this.checkBoxChange_radio(data, record.key);
        } else {
            //多选
            this.itemCheckBoxChange(data, record.key);
        }

        if (isFunction(checkboxChange)) {
            checkboxChange(record, index, e);
        }

        // 更新
        this.forceUpdate();
    };

    /* 查找对应的行数据 */
    findItem = (data, key) => {
        let res = null;
        let f = data => {
            data.map(item => {
                if (item.rowId === key) {
                    res = item;
                    return true;
                } else if (Array.isArray(item.values.children)) {
                    let r = f(item.values.children);
                    if (r) {
                        return false;
                    }
                }
            });
        };
        f(data);
        return res;
    };

    // 处理模板
    createNewCol = (data, maxDeep) => {
        let { showCheckBox, onCellMouseDown, store, multipleRowCell, bodyExpandAllInRow } = this.props;
        let { langJson } = this.state;
        let expandedKeys = store.getStore('expandedKeys');
        let checkedKeys = store.getStore('checkedKeys');
        let indeterminateKeys = store.getStore('indeterminateKeys');
        let oldSearchValue = store.getStore('searchValue');
        const multiYes = langJson && langJson['insert-tree-table-009'];
        const multiNo = langJson && langJson['insert-tree-table-010'];
        let newArr = [];

        if (!isArray(data) || data.length < 1) {
            return [];
        }

        data.forEach(item => {
            if (item.visible) {
                item._width = item._width || item.width || 120;
                item.width = item.width || 120;
                item.title = (
                    <span
                        fieldid={getSysFieldid(item.attrcode || 'opr')}
                        style={{ color: defaultColor.includes(item.color) ? '' : item.color }}
                    >
                        {' '}
                        {item.label}{' '}
                    </span>
                );
                item.dataIndex = item.attrcode;
                if (item.itemtype !== 'customer' && item.itemtype !== 'numberindex') {
                    if (!item.hasOwnProperty('render')) {
                        item.render = (text, record, index) => {
                            if (record && record.values.hasOwnProperty(item.attrcode)) {
                                if (item.itemtype === 'switch') {
                                    return (
                                        <span fieldid={getSysFieldid(item.attrcode)}
                                            onMouseDown={e => {
                                                onCellMouseDown && onCellMouseDown(record, index, item.attrcode, item, e);
                                            }}
                                        >
                                            {record.values[item.attrcode].value ? multiYes : multiNo}
                                        </span>
                                    );
                                } else {
                                    let langIndex;
                                    let langAttrCode;
                                    let context = '';
                                    if (item.itemtype === 'residtxt') {
                                        let LangCode = getLangCode();
                                        let loginLang = item.languageMeta.filter(i => i.languageCode == LangCode);
                                        if (loginLang[0]) {
                                            langIndex = loginLang[0].index == '1' ? '' : loginLang[0].index;
                                            langAttrCode = item.attrcode + langIndex;
                                        }
                                    }

                                    if (
                                        langAttrCode && record.values[langAttrCode] &&
                                        (record.values[langAttrCode].display || record.values[langAttrCode].value)
                                    ) {
                                        context =
                                            record.values[langAttrCode].display ||
                                            record.values[langAttrCode].value ||
                                            '';
                                    } else {
                                        context =
                                            record.values[item.attrcode].display ||
                                            record.values[item.attrcode].value ||
                                            '';
                                    }

                                    //格式化时间格式
                                    switch (item.itemtype) {
                                        case 'datetimepicker':
                                        case 'NCTZDatePickClientTime':
                                            context = changeTime(context, 'YYYY-MM-DD HH:mm:ss');
                                            break;
                                        case 'NCTZDatePickerStart':
                                        case 'NCTZDatePickerEnd':
                                        case 'NCTZDatePickClientHourTime':
                                        case 'datepicker':
                                            context = changeTime(context, 'YYYY-MM-DD');
                                            break;
                                    }
                                    switch (item.itemtype) {
                                        case 'NCTZDatePickerStart':
                                        case 'NCTZDatePickerEnd':
                                        case 'NCTZDatePickClientHourTime':
                                        case 'datePickerNoTimeZone':
                                        case 'datepicker':
                                            context = formatDatetime(context, 'date');
                                            break;
                                        case 'timepicker':
                                            context = formatDatetime(context, 'time');
                                            break;
                                        case 'datetimepicker':
                                        case 'NCTZDatePickClientTime':
                                            context = formatDatetime(context, 'datetime');
                                            break;
                                    }


                                    const fixedStyle = { display: '' };

                                    if (item.fixed && item.fixed === 'left') {
                                        if (item.width) {
                                            let width = String(item.width).replace('px', '');
                                            if (width != '120' && showCheckBox) {
                                                fixedStyle.width = item.width;
                                            }
                                        }
                                    }
                                    if (typeof context != 'string') {
                                        context += '';
                                    }
                                    // 添加高亮 by bbqin
                                    let searchValue = oldSearchValue || '';
                                    const searchValueLow = searchValue.toLocaleLowerCase();
                                    const contextLow = context.toLocaleLowerCase();
                                    let beforeStr = '';
                                    let afterStr = context;
                                    const index = contextLow.indexOf(searchValueLow);
                                    if (searchValue && index !== -1) {
                                        beforeStr = context.substr(0, index);
                                        afterStr = context.substr(index + searchValue.length);
                                        searchValue = context.substr(index, searchValue.length);
                                    }
                                    let isEllipsis = this.props.store.getStore('isEllipsis');
                                    return (
                                        <Cell
                                            item={item}
                                            beforeStr={beforeStr}
                                            afterStr={afterStr}
                                            searchValue={searchValue}
                                            fixedStyle={fixedStyle}
                                            context={context}
                                            isEllipsis={isEllipsis}
                                            multipleRowCell={multipleRowCell}
                                            bodyExpandAllInRow={bodyExpandAllInRow}
                                            index={index}
                                            record={record}
                                            onCellMouseDown={onCellMouseDown}
                                        />
                                    );
                                }
                            } else {
                                return <span
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'inline-block',
                                    }}
                                    onMouseDown={e => {
                                        onCellMouseDown && onCellMouseDown(record, index, item.attrcode, item, e);
                                    }}
                                ></span>;
                            }
                        };
                    }
                } else if (
                    item.itemtype === 'customer' &&
                    item.hasOwnProperty('render') &&
                    item.alreadyAssignment === undefined
                ) {
                    let Irender = item.render;
                    item.alreadyAssignment = true;
                    item.render = (text, record, index) => {
                        return (
                            <span fieldid={getSysFieldid('opr')} className={STOP_CHECK_OPERATION_ELEMENT}
                                onMouseDown={e => {
                                    onCellMouseDown && onCellMouseDown(record, index, item.attrcode, item, e);
                                }}
                            >
                                {Irender(text, record, index)}
                            </span>
                        );
                    };
                }
                newArr.push(item);
            }
        });
        let defaultColumns = [
            {
                title: <span fieldid={getSysFieldid('firstcol')} />,
                /*   title: showCheckBox ? (<Checkbox className="table-checkbox" fieldid={getSysFieldid('firstcol')}
                    onChange={this.selectAllChange} checked={checkedKeys["all"]}
                    indeterminate={indeterminateKeys["all"]} />) : <span fieldid={getSysFieldid('firstcol')} />, */
                attrcode: 'checkbox',
                dataIndex: 'checkbox',
                itemtype: 'customer',
                width: '54px',
                _width: '54px',
                render: (text, record, index) => {
                    // console.log(record, index);
                    if (showCheckBox) {
                        return (
                            <Checkbox
                                onMouseDown={e => {
                                    onCellMouseDown && onCellMouseDown(record, index, 'checkbox', {
                                        attrcode: 'checkbox',
                                        itemtype: 'customer',
                                    }, e);
                                }}
                                className="table-checkbox"
                                checked={checkedKeys[record.key]}
                                indeterminate={indeterminateKeys[record.key]}
                                onClick={this.onCheckboxChangeChild.bind(this, text, record, index)}
                            />
                        );
                    }
                },
            },
        ];

        if (newArr[0].fixed && newArr[0].fixed == 'left') {
            defaultColumns[0].fixed = 'left';
        }

        let resArr = showCheckBox ? defaultColumns.concat(newArr) : newArr;
        // 给第一行增加图标
        const oldFirstColRender = resArr[0].render;
        resArr[0].render = null;
        resArr[0].render = (text, record, index) => {
            let expendSwich;
            if ((Object.prototype.toString.call(record.isLeaf) == '[object Boolean]' && !record.isLeaf) ||
                (Object.prototype.toString.call(record.isleaf) == '[object Boolean]' && !record.isleaf)) {
                expendSwich = expandedKeys.includes(record.key) ? (
                    <i
                        className={`icon iconfont  icon-wenjianjiadakai tree-wenjian treeTableIcon ${STOP_CHECK_ELEMENT}`}
                    />
                ) : (<i className={`icon iconfont  icon-wenjianjia tree-wenjian treeTableIcon ${STOP_CHECK_ELEMENT}`} />);
            } else {
                expendSwich = <i className="dian" style={{ display: 'none' }} />;
            }
            const res = oldFirstColRender(text, record, index);

            if (
                res &&
                res.props &&
                res.props.children &&
                res.props.children[0] &&
                res.props.children[0].props &&
                res.props.children[0].props.className === 'expend-switch'
            ) {
                return res;
            }

            if (res && res.props && res.props.className === 'table-checkbox') {
                return (
                    <span fieldid={getSysFieldid('firstcol')}
                        onMouseDown={e => {
                            onCellMouseDown && onCellMouseDown(record, index, 'checkbox', {
                                attrcode: 'checkbox',
                                itemtype: 'customer',
                            }, e);
                        }}
                    >
                        <span className="first-col">{res}</span>
                        <span className="expend-switch">{expendSwich}</span>
                    </span>
                );
            }

            return (
                <span
                    fieldid={getSysFieldid('firstcol')}
                    onMouseDown={e => {
                        onCellMouseDown && onCellMouseDown(record, index, 'checkbox', {
                            attrcode: 'checkbox',
                            itemtype: 'customer',
                        }, e);
                    }}

                    className={
                        classnames({ zaiyihang: showCheckBox })
                    }
                >
                    <span className="expend-switch">{expendSwich}</span>
                    <span className="first-col">{res}</span>
                </span >
            );
        };

        // 给第一列追加宽度
        let width = resArr[0]._width;
        let _width = resArr[0]._width;
        if (width) {
            if (typeof width === 'string') {
                if (width.includes('px')) {
                    width = width.replace('px', '');
                }
                width = Number(width);
            }
            let deep = countDeep(maxDeep);
            let isleaf = true;
            if(Array.isArray(maxDeep)){
                if(maxDeep.find(x=>x.isleaf === false)){
                    isleaf = false;
                }
            }
            width = (deep <= 1 && isleaf) ? width : width + deep * 16 + 30;
        }
        resArr[0].width = width;
        resArr[0].resetWidth = Number(width) !== Number(_width)
        return resArr;
    };

    //	展开行
    onExpand = (status, item, e) => {
        let { store, expandEve, collapandEve } = this.props;
        let isCheckedHasChild = store.getStore('isCheckedHasChild')
        if (status) {
            if (expandEve && typeof expandEve === 'function') {
                store.setStore('currentRow', item, false);
                expandEve(item, isCheckedHasChild);
            }
        } else {
            if (isFunction(collapandEve)) {
                collapandEve(item);
            }
        }
        // 更新
        this.forceUpdate();
    };

    // 新增节点弹出框内容
    modalContent = () => {
        const { store, onAfterEvent, onBeforeEvent } = this.props;
        let formStore = store.getStore('formStore');
        // console.log(this.uuid);

        return (
            <div className="addModal">
                <Form
                    name={this.uuid}
                    config={{
                        onAfterEvent,
                        onBeforeEvent,
                    }}
                    store={formStore}
                />
            </div>
        );
    };

    // 删除 事件回调
    delNodeEve = () => {
        let { delNodeCallBack } = this.props;
        if (isFunction(delNodeCallBack)) {
            delNodeCallBack(this.uuid);
        }
    };

    // 模态框确定按钮事件
    beSureBtnClick = () => {
        let { modal, store, addNodeCallBack, editNodeCallBack } = this.props;
        let modalType = store.getStore('modalType');
        let currentRow = store.getStore('currentRow');
        let formStore = store.getStore('formStore');
        let isValid = formStore.isCheckNow();
        // console.log(isValid); TODO
        if (!isValid) {
            return false;
        }
        let data = { values: JSON.parse(JSON.stringify(formStore.getAllFormValue())) };
        if (modalType === 'add') {
            data.status = 2;
            if (isFunction(addNodeCallBack)) {
                addNodeCallBack(data, currentRow);
            }
        } else if (modalType === 'edit') {
            if (isFunction(editNodeCallBack)) {
                editNodeCallBack(data, currentRow);
            }
        }
        modal.close('treeTableManyColModal');
    };

    // 双击行
    onRowDoubleClick = (record, index, event) => {
        let { ncOnRowDoubleClick } = this.props;
        if (isFunction(ncOnRowDoubleClick)) {
            ncOnRowDoubleClick(record, index, event);
        }
    };

    // 单击行
    onRowClick = (row, index, e) => {
        let { store, checkOnRowClick, showCheckBox, onRowClickEve } = this.props;
        store.setStore('currentSelectedRow', row, false);
        // 为了避免和新开发的行框选点选区域选事件冲突
        if (e.ctrlKey || e.shiftKey || e.metaKey) {
            return;
        }
        if (showCheckBox && stopCheckBoxChange(e, checkOnRowClick)) return;
        if (isFunction(onRowClickEve)) {
            onRowClickEve(row, index, e);
        }
        this.onCheckboxChangeChild(null, row, index, e);
    };

    // 行样式
    rowClassName = (record, current) => {
        let { store } = this.props;
        let currentSelectedRow = store.getStore('currentSelectedRow');
        if (currentSelectedRow && currentSelectedRow.key == record.key) {
            return 'tree-table-col-selected';
        } else {
            return '';
        }

    };

    // 菜单
    createContextMenu = () => {
        let { store, closeAllEve, hideExpandAll, expandAllEve, hideIncludeChild, extendRightMenu } = this.props;
        let { langJson } = this.state;
        let data = store.getData();
        let isCheckedHasChild = store.getStore('isCheckedHasChild');

        return <Fragment>
            {
                !hideExpandAll && <li
                    className={
                        classnames({ 'menu expand-all': true })
                    }
                    onClick={() => {
                        let expandedKeys = [];
                        function getAllExpandKeys(data) {
                            data.forEach(item => {
                                expandedKeys.push(item.key);
                                if (Array.isArray(item.children)) {
                                    getAllExpandKeys(item.children);
                                }
                            })
                        }
                        getAllExpandKeys(data)

                        // 带更新
                        store.setStore('expandedKeys', expandedKeys);

                        //若为异步，调用业务全部展开回调
                        isFunction(expandAllEve) && expandAllEve()
                    }}
                >
                    {langJson['insert-tree-table-0013'] || '全部展开'}
                </li>
            }
            <li
                className={
                    classnames({ 'menu close-all': true })
                }
                onClick={() => {
                    // 更新
                    store.setStore('expandedKeys', []);
                    //业务全部收起的回调
                    isFunction(closeAllEve) && closeAllEve();
                }}
            >
                {langJson['insert-tree-table-0014'] || '全部收起'}
            </li>

            {
                !hideIncludeChild && <li
                    className={
                        classnames({ 'menu include-child': true })
                    }
                    onClick={() => {
                        // 带更新
                        store.setStore('isCheckedHasChild', !isCheckedHasChild);
                    }}
                >
                    <Checkbox
                        className={'include-child-checkbox'}
                        checked={isCheckedHasChild}
                        onChange={() => {
                            store.setStore('isCheckedHasChild', !isCheckedHasChild);
                        }}
                    />
                    <span className={
                        classnames({ 'include-child-selected': isCheckedHasChild })
                    }> {langJson['insert-tree-table-0015'] || '包含下级'}</span>
                </li>
            }

            {isFunction(extendRightMenu) && extendRightMenu()}
        </Fragment>
    }

    getColSetConfig = () => { //---by liuxis 鼠标不做列设置
        let { id, store } = this.props
        const { getMeta } = store;
        let meta = getMeta();
        const appcode = meta.appcode || meta.code || pageTo.getAppCode();
        const pagecode = meta.pagecode || pageTo.getPageCode();
        const areaCode = id;
        // return { appcode: '1', pagecode: '2', areaCode: '3' };
        return { appcode, pagecode, areaCode };
    };
    render() {
        let {
            modal,
            id,
            store,
            showCheckBox = false, // 是否需要复选框   默认false不显示
            checkedType = 'checkbox', //勾选方式  多选/单选  checkbox/radio
            checkboxChange,
            scrollConfig,
            isDrag = true,
            adaptionHeight = true, //高度是否自适应
            otherAreaHeight,
            inModal = false,
            fieldid,
            isLazyload = true,
            addBlankCol = true,
            showContextMenu = false,
            extendRightMenu,
            showWidthAndHeightConfig = true,
            hoverContent = getGlobalStorage("localStorage", "btnRenderModel"), // true
        } = this.props;

        let { langJson } = this.state;

        const { getStore, getViewMeta, getData, setStore } = store;
        // 模板
        let columns = getViewMeta(id);

        let oprColumn = null;

        if (hoverContent) {
            columns = [...columns];
            columns.forEach((item, index) => {
                if (
                    item.attrcode === "opr" &&
                    item.itemtype === "customer" &&
                    item.render
                ) {
                    oprColumn = item;
                    // 移除下  这里直接移除可能有问题
                    columns.splice(index, 1);
                }
            });
        }

        // 全数据对象
        let fullDataObject = {};
        // 数据   this.createNewDataRes
        let data = getData();

        if (!columns || columns.length === 0 || !data) {
            // 没有列模板就不渲染  避免特殊情况导致 操作列等列显示展开符号
            return null;
        }

        let treeData = this.createNewData(data, fullDataObject);
        // 记录下给 checkbox 渲染用 用于渲染checkbox 父子集关系
        // console.log(fullDataObject);
        store.setStore('fullDataObject', fullDataObject, false);
        // 最大深度
        const maxDeep = JSON.parse(JSON.stringify(treeData));
        // 展开项
        let expandedKeys = getStore('expandedKeys');
        // 表格高度
        let tableHeight = getStore('tableHeight');

        // console.log(store, columns);
        return (
            <div id={id} className="treeTableManyCol" fieldid={getSysFieldid(`${fieldid || id}_table`)}>
                {showCheckBox && checkedType !== 'radio' && (
                    <NCSelectable
                        canvas={`#${id}`}
                        // 注意可以加下前后空格
                        selector=" tbody tr "
                        unitKey="key"
                        disabled={checkedType === 'radio' || 'slide'} // 可选值 true false 'shift' 'ctrl' 'slide'
                        userSelect={true} // 启用用户文字选中
                        // unitsData={fullDataObject}  // 用dom做shift 操作 数据的问题是目前顺序不对 // 数组改为对象 给下面使用
                        // 开始项  主要配合外部的 单选事件处理
                        startItem=""
                        onSelect={(selectedRowKeys, hotkey, { currentKey }) => {
                            // 树状数据不太好处理 需要一个拉平数据
                            let rowLen = Object.keys(selectedRowKeys).length;
                            if (rowLen > 0) {
                                if (hotkey === 'ctrl') {
                                    setStore(['checkedKeys', currentKey], selectedRowKeys[currentKey], true, () => {
                                        let selectedRecord = fullDataObject[currentKey].item;
                                        console.log(fullDataObject, currentKey, selectedRecord, '数据的问题处理');
                                        if (isFunction(checkboxChange) && selectedRecord) {
                                            checkboxChange(selectedRecord, selectedRecord.rowIndex, {});
                                        }
                                    });
                                } else {
                                    setStore('checkedKeys', {});
                                    for (const key in selectedRowKeys) {
                                        if (selectedRowKeys.hasOwnProperty(key)) {
                                            setStore(['checkedKeys', key], selectedRowKeys[key], false);
                                        }
                                    }

                                    // 更新
                                    store.refresh(() => {
                                        // TODO 先使用循环调用时间 如果不行的话  后面需要单独加一个回调
                                        for (const key in selectedRowKeys) {
                                            if (selectedRowKeys.hasOwnProperty(key) && isFunction(checkboxChange)) {
                                                let selectedRecord = fullDataObject[key];
                                                selectedRowKeys[key] && checkboxChange(selectedRecord, selectedRecord.rowIndex, {});
                                            }
                                        }
                                    });
                                }
                            }
                        }}
                    />
                )}
                <Table
                    lazyload={isLazyload}
                    className={classnames("tree-table-manyCol-container", { 'firstcol-ellipsis': this.props.store.getStore('isEllipsis') })}
                    expandedRowKeys={expandedKeys} //搜索节点关键字后要展开的父节点
                    columns={this.createNewCol(columns, maxDeep)}
                    columnKey='attrcode'
                    data={treeData}
                    onExpand={this.onExpand}
                    onExpandedRowsChange={expandedRowKeys => {
                        // 更新
                        store.setStore('expandedKeys', expandedRowKeys);
                    }}
                    bodyDisplayInRow={false}
                    //折行回调
                    foldingRowChange={isEllipsis => {
                        store.setStore('isEllipsis', isEllipsis);
                        if (this.TreeTableRef && this.TreeTableRef.current && this.TreeTableRef.current.table) {
                            let { table = {} } = this.TreeTableRef.current.table;
                            table.manualSyncFixedTableRowHeight && table.manualSyncFixedTableRowHeight();
                        }
                    }}
                    scroll={scrollConfig || { y: tableHeight }}
                    bodyStyle={{ minHeight: (scrollConfig && scrollConfig.y) ? scrollConfig.y : 60 }}
                    onRowDoubleClick={this.onRowDoubleClick}
                    adaptionHeight={adaptionHeight}
                    otherAreaHeight={otherAreaHeight}
                    inModal={inModal}
                    onRowClick={this.onRowClick}
                    isDrag={isDrag}
                    ref={dom => {
                        this.dom = ReactDOM.findDOMNode(dom);
                    }}
                    rowClassName={this.rowClassName}
                    addBlankCol={addBlankCol}
                    extendRightMenu={
                        showContextMenu ? this.createContextMenu() :
                            isFunction(extendRightMenu) ? extendRightMenu() : null
                    }
                    cancelCustomRightMenu={true}
                    colsSettingParam={this.getColSetConfig()}
                    showWidthAndHeightConfig={showWidthAndHeightConfig}
                    treeTableRef={this.TreeTableRef}
                    hoverContent={
                        oprColumn && hoverContent
                            ? (record, index) => {
                                console.log(record, index);
                                return (
                                    <div className="hover-content">
                                        {record ? oprColumn.render("", record, index) : ""}
                                    </div>
                                );
                            }
                            : undefined
                    }
                />

                {langJson ? (
                    modal.createModal('delNode', {
                        title: langJson['insert-tree-table-001'],
                        content: langJson['insert-tree-table-002'],
                        beSureBtnClick: this.delNodeEve.bind(this),
                        backdrop: 'static',
                    })
                ) : null}

                {modal.createModal('treeTableManyColModal', {
                    content: this.modalContent.call(this),
                    beSureBtnClick: this.beSureBtnClick.bind(this),
                    cancelBtnClick: () => {
                        modal.close('treeTableManyColModal');
                    },
                    size: 'lg',
                    userControl: true,
                    backdrop: 'static',
                })}

            </div>
        );
    }
}

TreeTable.displayName = 'TreeTable';
TreeTable.useTreeTable = useTreeTable;

export default TreeTable;
