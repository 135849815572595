/*
 * @Author: liyxt
 * @Date: 2019-10-21 14:15:59
 * @LastEditors: bbq
 * @LastEditTime: 2020-08-07 14:28:12
 * @Description: file content
 */
/*
 *  	树状表组件
 * */
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { Table, Checkbox, Selectable as NCSelectable } from '@platform/base';
import Cell from './Cell';
import { isFunction, isArray, cloneObj, getLangCode, getMultiLang, getSysFieldid } from '@platform/api'
import { countDeep, stopCheckBoxChange } from './methods';
import { SAVE_MULTI, STOP_CHECK_ELEMENT, STOP_CHECK_OPERATION_ELEMENT } from './containers';
import { getChildren } from './methods';
const defaultColor = ['#111111', '#555555', '#111', '#555'];

require('./treeTableManyCol.less');

let isMultilingual = false;
// 计算主表表体高度
let tableHeight = 0;
const screenHeight = window.screen.width;

if (screenHeight <= 1919 > 1440) {
    tableHeight = 48 * 10 + 'px';
} else if (screenHeight > 1919) {
    tableHeight = 56 * 10 + 'px';
} else {
    tableHeight = 40 * 10 + 'px';
}

let formId = null;
function TreeTableManyCol(
    id,
    {
        expandEve, //异步执行，点击加号展开子节点
        collapandEve, //异步执行，点击收起
        async, // 加载方式，同步或异步
        editNodeCallBack, //编辑 回调
        addNodeCallBack, //新增回调
        delNodeCallBack, // 删除回调
        onAfterEvent,
        showCheckBox = false, // 是否需要复选框   默认false不显示
        checkedType = 'checkbox', //勾选方式  多选/单选  checkbox/radio
        checkboxChange,
        scrollConfig,
        ncOnRowDoubleClick,
        isDrag = true,
        multipleRowCell = false,
        adaptionHeight = true, //高度是否自适应
        inModal = false,
        checkOnRowClick = true, //业务组配置点击行时勾选复选框
        fieldid,
        onRowClickEve, //单击行回调
        isLazyload = true,
        addBlankCol = true,
        showContextMenu = false,
        //全部展开回调
        expandAllEve,
        //收起全部
        closeAllEve,
        hideExpandAll = false,
        hideIncludeChild = false,
        onCellMouseDown,
        extendRightMenu,
        isCheckedHasChild = false,
        bodyExpandAllInRow =false, // 折行展开全部
    } = {},
) {
    let flag = false;
    let that = this;
    if (!this.state.treeTableCol.hasOwnProperty(id)) {
        this.state.treeTableCol[id] = {
            firstTime: true,
            async,
            expandedKeys: [],
            checkedKeys: {},
            tableHeight,
            isEllipsis: true,
            bodyExpandAllInRow,
        };
    }
    let columns = this.state.meta[id];

    if (!columns) {
        return;
    }
    if (!this.state.treeTableCol[id].data) {
        return;
        // this.state.treeTableCol[id].data = [];
    }

    let thisTable = this.state.treeTableCol[id];

    if(typeof thisTable.isCheckedHasChild === "undefined"){
        thisTable.isCheckedHasChild = isCheckedHasChild;
    }
    
    let treeData = thisTable.data;
    let defTreeData = JSON.parse(JSON.stringify(treeData));
    let defCol = cloneObj(columns);

    //调用多语
    const callback = (json, bool, LangData) => {
        if (isMultilingual) return;
        thisTable.multilingual = json;
        SAVE_MULTI.lang = json;
        that.setState(
            { treeTableCol: that.state.treeTableCol },
            () => {
                isMultilingual = true;
            },
        );
    };

    getMultiLang({ moduleId: 'containers_insert_tree_table', callback });

    //	第一次渲染，处理数据，并设置编辑弹出模态框模板
    if (thisTable.firstTime) {
        formId = id + 'form';
        let formItem = [];
        if (!isArray(defCol.items)) {
            console.error('树状表模板中，items应为数组,请参考文档');
            return;
        }
        defCol.items.map(item => {
            if (item.itemtype !== 'customer') {
                // item 内部东西太复杂 直接给会导致一些问题
                let nItem = JSON.parse(
                    JSON.stringify(item || {}, (key, value) => {
                        if (key == 'render' || key == 'title') {
                            // title 和 value 是react对象  会导致问题
                            return undefined;
                        }
                        return value;
                    }),
                );
                delete nItem.render;
                delete nItem.title;
                formItem.push(nItem);
            }
        });
        this.state.meta[formId] = {
            moduletype: 'form',
            items: formItem,
            status: 'edit',
        };
        this.use.form(formId);
        this.formDataSource[formId].setMeta(this.state.meta, formId);
        thisTable.firstTime = false;
    }

    /* setState */
    const setStateEve = () => {
        this.setState({ treeTableCol: this.state.treeTableCol });
    };

    // 查找数据类型，并返回
    const checkDataType = field => {
        let data = columns.items.find(item => item.attrcode === field);
        if (data) {
            return data.itemtype;
        }
    };
    // 设置一个拉平数据 给框选用  超大数据量时不知道有没有性能问题 by bbqin
    // 由于框选需要数据顺序 现在取出来的顺序不对 后面再调整
    let fullDataArray = {};

    //  处理数据格式
    const createNewData = data => {
        const dataSource = data.map((item, index) => {
            item.key = item.rowId;
            // 记录一下行顺序给 框选使用
            item.rowIndex = index;
            if (item.values.pid) {
                item.pid = item.values.pid.value;
            }
            if (Array.isArray(item.children)) {
                createNewData(item.children);
            }
            fullDataArray[item.key] = item;
            return item;
        });
        return dataSource;
    };

    const createNewDataRes = createNewData(defTreeData);
    const cp = JSON.parse(JSON.stringify(createNewDataRes));

    //   获取到最深层级
    //   countDeep(cp)

    /* 复选框change事件(多选) */
    const itemCheckBoxChange = (data, key) => {
        thisTable.checkedKeys[key] = !thisTable.checkedKeys[key];
        //包含下级时，全部选中
        if (thisTable.isCheckedHasChild && showContextMenu) {
            let children = getChildren(data, key);
            console.log("设置children选中:  children", children)
            setChecked(children);
            function setChecked(children) {
                if (Array.isArray(children)) {
                    children.forEach(item => {
                        thisTable.checkedKeys[item.key] = thisTable.checkedKeys[key];
                        if (Array.isArray(item.children)) {
                            setChecked(item.children);
                        }
                    })
                }
            }
        }
    };

    /* 复选框change事件(单选) */
    const checkBoxChange_radio = (data, key) => {

        if (thisTable.checkedKeys[key]) {
            thisTable.checkedKeys = {};
        } else {
            thisTable.checkedKeys = { [key]: true };
        }

        // thisTable.isCheckedHasChild
    };

    /* 复选框选中事件 */
    const onCheckboxChangeChild = (text, record, index, e) => {
        e.stopPropagation();
        if (checkedType === 'radio') {
            //单选
            checkBoxChange_radio(thisTable.data, record.key);
        } else {
            //多选
            itemCheckBoxChange(thisTable.data, record.key);
        }
        if (isFunction(checkboxChange)) {
            checkboxChange(record, index, e);
        }

        setStateEve();
    };

    /* 查找对应的行数据 */
    const findItem = (data, key) => {
        let res = null;
        let f = data => {
            data.map(item => {
                if (item.rowId === key) {
                    res = item;
                    return true;
                } else if (Array.isArray(item.values.children)) {
                    let r = f(item.values.children);
                    if (r) {
                        return false;
                    }
                }
            });
        };
        f(data);
        return res;
    };

    // 处理模板
    const createNewCol = data => {
        const multiYes = thisTable.multilingual && thisTable.multilingual['insert-tree-table-009'];
        const multiNo = thisTable.multilingual && thisTable.multilingual['insert-tree-table-010'];
        let newArr = [];
        data.forEach(item => {
            if (item.visible) {
                item._width = item._width || item.width || 120;
                item.width = item.width || 120;
                item.title = (
                    <span
                        fieldid={getSysFieldid(item.attrcode || 'opr')}
                        style={{ color: defaultColor.includes(item.color) ? '' : item.color }}
                    >
                        {' '}
                        {item.label}{' '}
                    </span>
                );
                item.dataIndex = item.attrcode;
                if (item.itemtype !== 'customer' && item.itemtype !== 'numberindex') {
                    if (!item.hasOwnProperty('render')) {
                        item.render = (text, record, index) => {
                            if (record && record.values.hasOwnProperty(item.attrcode)) {
                                if (item.itemtype === 'switch') {
                                    return (
                                        <span fieldid={getSysFieldid(item.attrcode)}
                                            onMouseDown={e=>{
                                                onCellMouseDown && onCellMouseDown(record, index, item.attrcode, item, e);  
                                            }}
                                        >
                                            {record.values[item.attrcode].value ? multiYes : multiNo}
                                        </span>
                                    );
                                } else {
                                    let langIndex;
                                    let langAttrCode;
                                    let context = '';
                                    if (item.itemtype === 'residtxt') {
                                        let LangCode = getLangCode();
                                        let loginLang = item.languageMeta.filter(i => i.languageCode == LangCode);
                                        if (loginLang[0]) {
                                            langIndex = loginLang[0].index == '1' ? '' : loginLang[0].index;
                                            langAttrCode = item.attrcode + langIndex;
                                        }
                                    }
                                    if (
                                        langAttrCode &&
                                        (record.values[langAttrCode].display || record.values[langAttrCode].value)
                                    ) {
                                        context =
                                            record.values[langAttrCode].display ||
                                            record.values[langAttrCode].value ||
                                            '';
                                    } else {
                                        context =
                                            record.values[item.attrcode].display ||
                                            record.values[item.attrcode].value ||
                                            '';
                                    }

                                    const fixedStyle = { display: '' };

                                    if (item.fixed && item.fixed === 'left') {
                                        if (item.width) {
                                            let width = String(item.width).replace('px', '');
                                            if (width != '120' && showCheckBox) {
                                                fixedStyle.width = item.width;
                                            }
                                        }
                                    }
                                    if (typeof context != 'string') {
                                        context += '';
                                    }
                                    // 添加高亮 by bbqin
                                    let searchValue = thisTable.searchValue || '';
                                    const searchValueLow = searchValue.toLocaleLowerCase();
                                    const contextLow = context.toLocaleLowerCase();
                                    let beforeStr = '';
                                    let afterStr = context;
                                    const index = contextLow.indexOf(searchValueLow);
                                    if (searchValue && index !== -1) {
                                        beforeStr = context.substr(0, index);
                                        afterStr = context.substr(index + searchValue.length);
                                        searchValue = context.substr(index, searchValue.length);
                                    }
                                    return (
                                        <Cell
                                            item={item}
                                            beforeStr={beforeStr}
                                            afterStr={afterStr}
                                            searchValue={searchValue}
                                            fixedStyle={fixedStyle}
                                            context={context}
                                            isEllipsis={thisTable.isEllipsis}
                                            bodyExpandAllInRow={thisTable.bodyExpandAllInRow}
                                            multipleRowCell={multipleRowCell}
                                            index={index}
                                            record={record}
                                            onCellMouseDown={onCellMouseDown}
                                        />
                                    );
                                }
                            } else {
                                return <span
                                    style={{
                                        width:'100%',
                                        height: '100%',
                                        display: 'inline-block'
                                    }}
                                    onMouseDown={e=>{
                                        onCellMouseDown && onCellMouseDown(record, index, item.attrcode, item, e);  
                                    }}
                                ></span>;
                            }
                        };
                    }
                } else if (
                    item.itemtype === 'customer' &&
                    item.hasOwnProperty('render') &&
                    item.alreadyAssignment === undefined
                ) {
                    let Irender = item.render;
                    item.alreadyAssignment = true;
                    item.render = (text, record, index) => {
                        return (
                            <span fieldid={getSysFieldid('opr')} className={STOP_CHECK_OPERATION_ELEMENT} 
                                onMouseDown={e=>{
                                    onCellMouseDown && onCellMouseDown(record, index, item.attrcode, item, e);  
                                }}
                            >
                                {Irender(text, record, index)}
                            </span>
                        );
                    };
                }
                newArr.push(item);
            }
        });
        let defaultColumns = [
            {
                title: <span fieldid={getSysFieldid('firstcol')} />,
                attrcode: 'checkbox',
                dataIndex: 'checkbox',
                itemtype: 'customer',
                width: '100px',
                _width: '100px',
                render: (text, record, index) => {
                    // let item = findItem( thisTable.data, record.key);
                    // console.log(record, index);
                    if (showCheckBox) {
                        return (
                            <Checkbox
                                onMouseDown={e=>{
                                    onCellMouseDown && onCellMouseDown(record, index, 'checkbox', {
                                        attrcode: 'checkbox',
                                        itemtype: 'customer'
                                    }, e);  
                                }}
                                className="table-checkbox"
                                checked={thisTable.checkedKeys[record.key]}
                                onClick={onCheckboxChangeChild.bind(this, text, record, index)}
                            />
                        );
                    }
                },
            },
        ];

        if (newArr[0].fixed && newArr[0].fixed == 'left') {
            defaultColumns[0].fixed = 'left';
        }

        let resArr = showCheckBox ? defaultColumns.concat(newArr) : newArr;
        // 给第一行增加图标
        const oldFirstColRender = resArr[0].render;
        resArr[0].render = null;
        resArr[0].render = (text, record, index) => {
            let expendSwich;
            if ((Object.prototype.toString.call(record.isLeaf) == '[object Boolean]' && !record.isLeaf) ||
                (Object.prototype.toString.call(record.isleaf) == '[object Boolean]' && !record.isleaf)) {
                expendSwich = thisTable.expandedKeys.includes(record.key) ? (
                    <i
                        className={`icon iconfont  icon-wenjianjiadakai tree-wenjian treeTableIcon ${STOP_CHECK_ELEMENT}`}
                    />
                ) : (
                        <i className={`icon iconfont  icon-wenjianjia tree-wenjian treeTableIcon ${STOP_CHECK_ELEMENT}`} />
                    );
            } else {
                expendSwich = <i className="dian" style={{ display: 'none' }} />;
            }
            const res = oldFirstColRender(text, record, index);

            if (
                res &&
                res.props &&
                res.props.children &&
                res.props.children[0] &&
                res.props.children[0].props &&
                res.props.children[0].props.className === 'expend-switch'
            ) {
                return res;
            }

            if (res && res.props && res.props.className === 'table-checkbox') {
                return (
                    <span fieldid={getSysFieldid('firstcol')}
                        onMouseDown={e=>{
                            onCellMouseDown && onCellMouseDown(record, index, 'checkbox', {
                                attrcode: 'checkbox',
                                itemtype: 'customer'
                            }, e);  
                        }}
                    >
                        <span className="first-col">{res}</span>
                        <span className="expend-switch">{expendSwich}</span>
                    </span>
                );
            }

            return (
                <span
                    fieldid={getSysFieldid('firstcol') }
                    onMouseDown={e=>{
                        onCellMouseDown && onCellMouseDown(record, index, 'checkbox', {
                            attrcode: 'checkbox',
                            itemtype: 'customer'
                        }, e);  
                    }}
                   
                    className={
                        classnames({ zaiyihang: showCheckBox })
                    }
                >
                    <span className="expend-switch">{expendSwich}</span>
                    <span className="first-col">{res}</span>
                </span >
            );
        };

        // 给第一列追加宽度
        let width = resArr[0]._width;
        if (!flag && width) {
            if (typeof width === 'string') {
                if (width.includes('px')) {
                    width = width.replace('px', '');
                }
                width = Number(width);
            }
            width += countDeep(cp) * 16;
            flag = true;
        }
        resArr[0].width = width;
        return resArr;
    };

    //	展开行
    const onExpand = (status, item, e) => {
        if (status) {
            if (expandEve && typeof expandEve === 'function') {
                thisTable.currentRow = item;
                expandEve(item, thisTable.isCheckedHasChild);
            }
        } else {
            if (isFunction(collapandEve)) {
                collapandEve(item);
            }
        }
        setStateEve();
    };

    // 新增节点弹出框内容
    const modalContent = () => {
        return (
            <div className="addModal">
                {this.form.createForm(formId, { onAfterEvent })}
            </div>
        );
    };

    // 删除 事件回调
    const delNodeEve = () => {
        if (isFunction(delNodeCallBack)) {
            delNodeCallBack(formId);
        }
    };

    // 模态框确定按钮事件回调
    const beSureBtnClick = () => {
        let isValid = this.form.isCheckNow(formId);
        if (!isValid) {
            return false;
        }
        let data = this.form.getAllFormValue(formId).rows[0];
        if (thisTable.modalType === 'add') {
            data.status = 2;
            if (isFunction(addNodeCallBack)) {
                addNodeCallBack(data, thisTable.currentRow);
            }
        } else if (thisTable.modalType === 'edit') {
            if (isFunction(editNodeCallBack)) {
                editNodeCallBack(data, thisTable.currentRow);
            }
        }
        this.modal.close('treeTableManyColModal');
    };

    const onRowDoubleClick = (record, index, event) => {
        if (isFunction(ncOnRowDoubleClick)) {
            ncOnRowDoubleClick(record, index, event);
        }
    };

    // const getColSetConfig = () => { //---by liuxis 鼠标不做列设置
    //   const appcode = pageTo.getAppCode();
    //   const pagecode = pageTo.getPageCode();
    //   const areaCode = id;
    //   const areaId = this.state.meta[areaCode] && this.state.meta[areaCode].oid;
    //   const code = this.state.meta.code;
    //   const pageId = this.state.meta.pageid;
    //   return { appcode, pagecode, areaCode, areaId, code, pageId };
    // };

    /**
    * @param row 行数据
    * @param index 行数
    * @param e 事件源
    */
    const onRowClick = (row, index, e) => {
        thisTable.currentSelectedRow = row;
        // 为了避免和新开发的行框选点选区域选事件冲突
        if (e.ctrlKey || e.shiftKey || e.metaKey) {
            return;
        }
        if (showCheckBox && stopCheckBoxChange(e, checkOnRowClick)) return;
        if (isFunction(onRowClickEve)) {
            onRowClickEve(row, index, e);
        }
        onCheckboxChangeChild(null, row, index, e);
    };

    const rowClassName = (record, current) => {
        if (thisTable.currentSelectedRow && thisTable.currentSelectedRow.key == record.key) {
            return 'tree-table-col-selected';
        } else {
            return '';
        }

    };

    const createContextMenu = () => {
        let multilingual = thisTable.multilingual || {};

        return <Fragment>
            {
                !hideExpandAll && <li
                    className={
                        classnames({
                            'menu expand-all': true
                        })
                    }
                    onClick={() => {
                        let expandedKeys = [];
                        getAllExpandKeys(thisTable.data)
                        thisTable.expandedKeys = expandedKeys;
                        setStateEve();
                        function getAllExpandKeys(data) {
                            data.forEach(item => {
                                expandedKeys.push(item.key);
                                if (Array.isArray(item.children)) {
                                    getAllExpandKeys(item.children);
                                }
                            })
                        }
                        //若为异步，调用业务全部展开回调
                        isFunction(expandAllEve) && expandAllEve()
                    }}
                >
                    {multilingual['insert-tree-table-0013'] || '全部展开'}
                </li>
            }
            <li
                className={
                    classnames({
                        'menu close-all': true
                    })
                }
                onClick={() => {
                    thisTable.expandedKeys = [];
                    setStateEve();
                    //业务全部收起的回调
                    isFunction(closeAllEve) && closeAllEve();
                }}
            >
                {multilingual['insert-tree-table-0014'] || '全部收起'}
            </li>

            {
                !hideIncludeChild && <li
                    className={
                        classnames({
                            'menu include-child': true
                        })
                    }
                    onClick={() => {
                        thisTable.isCheckedHasChild = !thisTable.isCheckedHasChild;
                        setStateEve();
                    }}
                >
                    <Checkbox
                        className={'include-child-checkbox'}
                        checked={thisTable.isCheckedHasChild}
                        onChange={() => {
                            thisTable.isCheckedHasChild = !thisTable.isCheckedHasChild;
                            setStateEve();
                        }}
                    />
                    <span className={
                        classnames({
                            'include-child-selected': thisTable.isCheckedHasChild
                        })
                    }> {multilingual['insert-tree-table-0015'] || '包含下级'}</span>
                </li>
            }

            {isFunction(extendRightMenu) && extendRightMenu()}

        </Fragment>

    }


    return (
        <div id={id} className="treeTableManyCol" fieldid={getSysFieldid(`${fieldid || id}_table`)}>
            {showCheckBox && checkedType !== 'radio' ? (
                <NCSelectable
                    canvas={`#${id}`}
                    // 注意可以加下前后空格
                    selector=" tbody tr "
                    unitKey="key"
                    disabled={checkedType === 'radio' || 'slide'} // 可选值 true false 'shift' 'ctrl' 'slide'
                    userSelect={true} // 启用用户文字选中
                    // unitsData={fullDataArray}  // 用dom做shift 操作 数据的问题是目前顺序不对 // 数组改为对象 给下面使用
                    // 开始项  主要配合外部的 单选事件处理
                    startItem=""
                    onSelect={(selectedRowKeys, hotkey, { currentKey }) => {
                        // 树状数据不太好处理 需要一个拉平数据
                        let rowLen = thisTable.data.length;
                        if (rowLen > 0) {
                            if (hotkey === 'ctrl') {
                                thisTable.checkedKeys[currentKey] = selectedRowKeys[currentKey];
                                // thisTable.isCheckedHasChild

                                this.setState({ treeTableCol: this.state.treeTableCol }, () => {
                                    let selectedRecord = fullDataArray[currentKey];
                                    // console.log(fullDataArray, currentKey, selectedRecord, '数据的问题处理');
                                    if (isFunction(checkboxChange) && selectedRecord) {
                                        checkboxChange(selectedRecord, selectedRecord.rowIndex, {});
                                    }
                                });
                            } else {
                                thisTable.checkedKeys = {};
                                for (const key in selectedRowKeys) {
                                    if (selectedRowKeys.hasOwnProperty(key)) {
                                        thisTable.checkedKeys[key] = selectedRowKeys[key];

                                        // thisTable.isCheckedHasChild
                                    }
                                }
                                // TODO 先使用循环调用时间 如果不行的话  后面需要单独加一个回调
                                this.setState({ treeTableCol: this.state.treeTableCol }, () => {

                                    for (const key in selectedRowKeys) {
                                        if (selectedRowKeys.hasOwnProperty(key) && isFunction(checkboxChange)) {
                                            let selectedRecord = fullDataArray[key];
                                            selectedRowKeys[key] && checkboxChange(selectedRecord, selectedRecord.rowIndex, {});
                                        }
                                    }
                                });
                            }

                        }
                    }}
                />
            ) : (
                    ''
                )}
            <Table
                lazyload={isLazyload}
                className="tree-table-manyCol-container"
                expandedRowKeys={thisTable.expandedKeys} //搜索节点关键字后要展开的父节点
                columns={createNewCol(columns.items)}
                columnKey='attrcode'
                data={createNewDataRes}
                onExpand={onExpand.bind(this)}
                onExpandedRowsChange={expandedRowKeys => {
                    thisTable.expandedKeys = expandedRowKeys;
                    setStateEve();
                }}
                bodyDisplayInRow={false}
                //折行回调
                foldingRowChange={isEllipsis => {
                    thisTable.isEllipsis = isEllipsis
                    setStateEve();
                }}
                scroll={scrollConfig || { x: true, y: this.state.treeTableCol[id].tableHeight }}
                onRowDoubleClick={onRowDoubleClick}
                adaptionHeight={adaptionHeight}
                inModal={inModal}
                onRowClick={onRowClick}
                isDrag={isDrag}
                ref={dom => {
                    this.dom = ReactDOM.findDOMNode(dom);
                }}
                rowClassName={rowClassName}
                addBlankCol={addBlankCol}
                extendRightMenu={
                    showContextMenu ? createContextMenu() :
                    isFunction(extendRightMenu) ? extendRightMenu() : null
                }
                cancelCustomRightMenu={true}
            //bodyStyle={{ height: this.state.treeTableCol[id].tableHeight }}
            // colsSettingParam={getColSetConfig()}
            />

            {thisTable.multilingual ? (
                this.modal.createModal('delNode', {
                    title: thisTable.multilingual['insert-tree-table-001'],
                    content: thisTable.multilingual['insert-tree-table-002'],
                    beSureBtnClick: delNodeEve.bind(this),
                    backdrop: 'static',
                })
            ) : null}

            {this.modal.createModal('treeTableManyColModal', {
                content: modalContent.call(this),
                beSureBtnClick: beSureBtnClick.bind(this),
                cancelBtnClick: () => {
                    this.modal.close('treeTableManyColModal');
                },
                size: 'lg',
                userControl: true,
                backdrop: 'static',
            })}

        </div>
    );
}

export default TreeTableManyCol;
