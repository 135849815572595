/*
 * @Descripttion: 
 * @version: 
 * @Author: bbq
 * @Date: 2020-12-11 11:25:40
 * @LastEditors: bbq
 * @LastEditTime: 2021-01-14 11:08:05
 */
import TreeTableManyCol from './treeTableManyCol';
import TreeTable from './treeTable';
import useTreeTable from './store';
import {
    initTreeTableData,
    setChildNode,
    editRowEve,
    addChildRowEve,
    addBrotherRow,
    delRowByPk,
    createNewData,
    getAllValue,
    getValueByRowId,
    getExpandedRowKeys,
    showModal,
    emptyAllData,
    openRow,
    closeRow,
    getSelectedRow,
    searchTreeTable,
    updataDataSource,
    setCheckedKeys,
    setSelectedRow,
    updateChildRow,
    treeTableCol,
} from './methods';

let TreeTableAPI = {
    TreeTableManyCol,
    treeTableCol,
    initTreeTableData,
    setChildNode,
    editRowEve,
    addChildRowEve,
    addBrotherRow,
    delRowByPk,
    createNewData,
    getAllValue,
    getValueByRowId,
    getExpandedRowKeys,
    showModal,
    emptyAllData,
    openRow,
    closeRow,
    getSelectedRow,
    searchTreeTable,
    updataDataSource,
    setCheckedKeys,
    setSelectedRow,
    updateChildRow,
};

/**
 * 兼容旧版本创建表格方法
 * @param {string} mainCode 表格主区域编码
 * @param {object} config 表格业务配置
 */

export default function treeTableCompatible() {
    this.register("treeTable", useTreeTable); // 生成 this.treeTableDataSource[id]
    return { ...apiBindPage.call(this, TreeTableAPI) };
}

function apiBindPage(simpleTableAPI) {
    let res = {}
    for (let pop of Object.keys(simpleTableAPI)) {
        res[pop] = simpleTableAPI[pop].bind(this);
    }
    return res;
}

treeTableCompatible.component = TreeTable;
treeTableCompatible.displayName = "TreeTable";
treeTableCompatible.unfinished = ["treeTableUnFinishedApi"];
